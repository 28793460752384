<template>
  <ul :class="menuType">
    <li @click="changeComponent('MainScreen')" v-if="!MainScreenIsActive">
      <div class="link_img" ref="list_image">
        <img src="@/assets/img/icons/home.svg" />
      </div>
      <span>Главный экран</span>
    </li>
    <li @click="changeComponent('Accounting')">
      <div class="link_img" ref="list_image">
        <img src="@/assets/img/icons/accounting.svg" />
      </div>
      <span>Учёт</span>
    </li>
    <li @click="changeComponent('Statistics')">
      <div class="link_img">
        <img src="@/assets/img/icons/statistic.svg" />
      </div>
      <span>Статистика</span>
    </li>
    <li @click="changeComponent('Settings')">
      <div class="link_img">
        <img src="@/assets/img/icons/settings.svg" />
      </div>
      <span>Настройки</span>
    </li>
    <li @click="changeComponent('ParishArticles')">
      <div class="link_img">
        <img src="@/assets/img/icons/coming.svg" />
      </div>
      <span>Статьи приходов</span>
    </li>
    <li @click="changeComponent('Expenditure')">
      <div class="link_img">
        <img src="@/assets/img/icons/consumption.svg" />
      </div>
      <span>Статьи расходов</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["menuType"],
  data() {
    return {};
  },
  computed: {
    MainScreenIsActive() {
      return this.$store.getters["getActiveComponent"] == "MainScreen"
        ? true
        : false;
    },
  },
  methods: {
    changeComponent(payload) {
      this.$store.commit("setActiveComponent", payload);
      this.$store.commit("setMenuIsShown", false);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  li {
    box-sizing: border-box;
    max-width: calc(100% / 3 - 15px);
    width: 100%;
    background-color: #19516D;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px 5px;
    cursor: pointer;
    transition: .2s;
    &:nth-of-type(3n) {
      margin-right: 0;
    }

    .link_img {
      max-width: 45px;
      max-height: 45px;
      margin: 0 auto;
      img {
        max-width: 90%;
        max-height: 90%;
        object-fit: contain;
        width: 100%;
      }
    }
    span {
      margin: 10px auto 0 auto;
      text-align: center;
      display: table;
      font-size: 13px;
    }
    &:hover {
      background-color: #2a617c;
    }
  }

  &.menu {
    margin-top: -15px;
    li {
      max-width: 100%;
      display: flex;
      align-items: center;
      margin-right: 0;
      margin-bottom: 0;
      border-bottom: 1px solid #4183A4;
      padding: 20px 25px;
      .link_img {
        max-width: 24px;
        max-height: 24px;
        margin: 0;
        margin-right: 10px;
      }

      span {
        display: block;
        margin: 0;
        text-align: left;
        font-size: 16px;
      }

      &:first-of-type {
        border-top: 1px solid #4183A4;
      }

      &:last-of-type {
        border-radius: 0 0 22px 22px;
        border-bottom: unset;
      }
    }
  }
}
</style>