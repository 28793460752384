<template>
  <section>
    <components-list menu-type="menu"></components-list>
  </section>
</template>

<script>
import ComponentsList from './parts/ComponentsList';
export default {
  components: {
    ComponentsList
  }
};
</script>

<style lang = "scss">

</style>